import React, { useState, useEffect, useRef } from 'react';
import '../assets/layout.css';
import underConstruction from '../assets/images/under-construction.png'


const Layout = ({ children }) => {
  const [isUnderConstruction, setIsUnderConstruction] = useState(true);

  useEffect(() => {

    const layoutFirstChild = document.getElementById('layout')?.firstElementChild;

    if (layoutFirstChild) {
      // Ajouter ou retirer la classe 'blur' de layoutFirstChild selon l'état de la modale
      if (isUnderConstruction) {
        layoutFirstChild.classList.add('blur');
      } else {
        layoutFirstChild.classList.remove('blur');
      }
    }

    // Nettoyer l'effet lorsque le composant est démonté
    return () => {
      if (layoutFirstChild) {
        layoutFirstChild.classList.remove('blur');
      }
    };
  }, [isUnderConstruction]);

  useEffect(() =>{
    const modal = document.getElementById('modal');
    const scrollIndicator = document.getElementById('scroll-indicator');
    const checkScrollPosition = () => {
      // Si la hauteur de défilement de la modale dépasse la hauteur visible
      if (modal.scrollHeight > modal.clientHeight) {
        // Si l'utilisateur n'est pas encore en bas de la modale
        if (modal.scrollTop + modal.clientHeight < modal.scrollHeight - 70) {
          scrollIndicator.style.display = 'block';
        } else {
          scrollIndicator.style.display = 'none';
        }
      } else {
        scrollIndicator.style.display = 'none';
      }
    };

    if(modal){
      checkScrollPosition();
      modal.addEventListener('scroll', checkScrollPosition);
    }

    //nettoyage
    return () => {
      if (modal) {
        modal.removeEventListener('scroll', checkScrollPosition); // Supprime l'écouteur
      }
    };
  },[isUnderConstruction]);

  


  return (
    <div id='layout' className={`layout-container ${isUnderConstruction ? '' : 'hidden'}`}>
      {children}
      {isUnderConstruction && (
        <div className="overlay">
          <div id="modal">
            <img src={underConstruction} alt="Construction"/>
            
          
            <div className="modal-header">
              <h2>Site en construction</h2>
              
            </div>
            <div className="modal-body">
            <p>Ce site internet est créé sur-mesure et sans publicité, c'est pourquoi son élaboration nous prend du temps.</p>
            <p>Certaines fonctionnalité tel que le paiement en ligne ne sont pas encore disponibles.</p>
            <p>En raison du contenu limité, nous avons décidé de rendre toutes les ressources disponibles gratuitement pour le moment.</p>
            <p>Nous vous remercions de votre compréhension et nous travaillons activement pour vous offrir le meilleur.</p>
            <p>En attendant, vous pouvez nous contacter grâce aux coordonnées de la page contact ! </p> 
            </div>
            <button 
                className="close-btn clic-button primary-button" 
                onClick={() => setIsUnderConstruction(false)}
              >
                D'accord !
              </button>
              <div class="scroll-indicator" id="scroll-indicator">↓</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;