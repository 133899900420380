import React, { useState, useRef, useEffect } from 'react';
import AuthService from "../services/auth.service";
import { NavLink } from 'react-router-dom';
import {Tooltip} from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as faUserSolid, faPowerOff as faPowerOffSolid, faRightToBracket as faRightToBracketSolid} from '@fortawesome/free-solid-svg-icons';
import { faUser as faUserRegular} from '@fortawesome/free-regular-svg-icons';
import logoRondNom from '../assets/images/logo-rond-nom.png';
import '../assets/menu-stretchy.css';
import '../assets/bootstrap.css';
import '../assets/cruxologie.css'
import '../assets/navbar.css'
// import isTouchDevice from 'is-touch-device';

const AppNavbar = () => {

    const [faUser, setFaUser] = useState(faUserSolid);
    const [faPowerOff, setFaPowerOff] = useState(faPowerOffSolid)
    const [showClassicNav, setShowClassicNav] = useState(true);
    const [navVisible, setNavVisible] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);
    const [currentUser, setCurrentUser] = useState(undefined);
    const stretchyNav = useRef(null);
    const classicNav = useRef(null);
    const [isClassShown, setIsClassShown] = useState(false);
    const [showTooltipUser, setShowTooltipUser] = useState(false);
    const [showTooltipLog, setShowTooltipLog] = useState(false);
    const [isTouch, setIsTouch] = useState(isTouchDeviceFunction());
    const dropdownRef = useRef(null);
    const dropdownMenuRef = useRef(null);
    const timerRef = useRef(null);

    useEffect(() => {
        window.addEventListener('scroll', toggleNavDisplay);
        window.addEventListener('resize', toggleNavDisplay);
        window.addEventListener('load', toggleNavDisplay);
        document.addEventListener('mousedown', toggleNavByClick);
        // document.addEventListener('touchstart', isTouchDeviceFunction);
        // document.addEventListener('click', isTouchDeviceFunction);
        const userPromise = AuthService.getCurrentUser();
        userPromise.then(
          (user) => {
            setCurrentUser(user);
            setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
          },
          (error) => {
          }
        );

        toggleNavDisplay();
        setIsTouch(isTouchDeviceFunction())

        return () => {
            window.removeEventListener('scroll', toggleNavDisplay);
            window.removeEventListener('resize', toggleNavDisplay);
            window.removeEventListener('load', toggleNavDisplay);
            document.removeEventListener('mousedown', toggleNavByClick);
            // document.removeEventListener('touchstart', isTouchDeviceFunction);
            // document.removeEventListener('click', isTouchDeviceFunction);
        }
    }, [])

    useEffect(() => {
      const handleMouseMove = (event) => {
        if (
          isClassShown &&
          dropdownRef.current &&
          dropdownMenuRef.current &&
          !dropdownRef.current.contains(event.target) &&
          !dropdownMenuRef.current.contains(event.target)
        ) {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
              setIsClassShown(false);
            }, 500);
          } else {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
          }
      };
  
      document.addEventListener('mousemove', handleMouseMove);
  
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }, [isClassShown]);

    const toggleClass = () => {
        setIsClassShown(!isClassShown);
      };

    //   Utilisation des icônes user et log
      const handleMouseOverUser = () => {
        setShowTooltipUser(true);
        setFaUser(faUserRegular);
      }    
      const handleMouseLeaveUser = () => {
        setShowTooltipUser(false);
        setFaUser(faUserSolid);
      };
      const handleMouseOverLog = () => {
        setShowTooltipLog(true);
        setFaPowerOff(faRightToBracketSolid)
      }    
      const handleMouseLeaveLog = () => {
        setShowTooltipLog(false);
        setFaPowerOff(faPowerOffSolid)
      };
      
    const handleLogout = () => {
      localStorage.removeItem('user');    
      window.location.href = "/";
    };

      

    function toggleNavDisplay() {
        var scrollY = window.scrollY || window.pageYOffset;

        // Hauteur à partir de laquelle vous souhaitez basculer
        var threshold = 200; 

        var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if (screenWidth < 1000 || scrollY >= threshold) {
            // Si la largeur de l'écran est inférieure à 700 pixels, afficher uniquement nav2
            // L'utilisateur a commencé à scroller, donc afficher nav2 et masquer nav1
            setShowClassicNav(false);
        } else {
            // L'utilisateur est en haut de la page, donc afficher nav1 et masquer nav2
            setShowClassicNav(true);
            setNavVisible(false);
        }
    }

    function toggleNavByClick(e) {
        if (stretchyNav.current && !stretchyNav.current.contains(e.target)) {
            setNavVisible(false)
        }
    }
   function isTouchDeviceFunction() {
     return (
       (typeof window !== 'undefined' && 'ontouchstart' in window) ||
       (typeof navigator !== 'undefined' && navigator.maxTouchPoints > 0) ||
       (typeof navigator !== 'undefined' && navigator.msMaxTouchPoints > 0)
     );
   }
    

    return <header className='headerNavBar'>
        {showClassicNav ?
                <nav ref={classicNav} className="navbar navbar-expand-lg navbar-custom" id="bootNav">
                <div className="container">
                  <NavLink className="navbar-brand" to="/"><img src={logoRondNom} alt="logo accueil navigation" width="175" height="30" /></NavLink>
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink to="/article" className="nav-link" aria-current="page">Article</NavLink>
                    </li>
                    <li className="nav-item dropdown" ref={dropdownRef}>
                      <NavLink 
                      to={isTouch ? window.location.pathname : "/ressource"}
                      className={`nav-link dropdown-toggle togglerElement ${isClassShown ? 'show' : ''}`} 
                      onMouseOver={isTouch ? undefined : toggleClass} 
                      onClick={(event) => {
                        if (isTouch) {
                          toggleClass();
                        } else {
                          console.log("click");
                        }
                      }}                      
                      id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Ressources
                      </NavLink>
                      <div key={isClassShown ? 'show' : 'hide'} className={`dropdown-menu targetElement ${isClassShown ? 'show' : ''}`} aria-labelledby="navbarDropdown" onMouseLeave={isTouch ? undefined : toggleClass }  ref={dropdownMenuRef}>
                        <NavLink to="/ressource/exercice" className="dropdown-item">Exercices</NavLink>
                        <NavLink to="/ressource/programme" className="dropdown-item">Programmes d'entraînement</NavLink>
                        <NavLink to="/ressource/autre" className="dropdown-item">Autres ressources</NavLink>
                      </div>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/coaching" className="nav-link">Coaching</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/contact" className="nav-link" aria-current="page">Contact</NavLink>
                    </li>
                  </ul>
                        
                  {currentUser ? 
                    <div className="navbar-nav mr-auto">
                      {showAdminBoard ? <NavLink to="/admin">Admin</NavLink> : null}
                      <NavLink to="/profil" className="icon-user nav-link" data-tooltip-id="tt-profil" data-tooltip-html="Profil" data-tooltip-place="bottom">
                          <i><FontAwesomeIcon onMouseOver={handleMouseOverUser} onMouseLeave={handleMouseLeaveUser} icon={faUser} /></i>
                          <i className="fa-regular fa-user"></i>
                          <Tooltip id='tt-profil'/>
                      </NavLink> 
                      <div  className="icon-deconnexion nav-link" data-tooltip-id="tt-deconnexion" data-tooltip-html="Déconnexion" data-tooltip-place="bottom">
                            <i><FontAwesomeIcon onClick={handleLogout} onMouseOver={handleMouseOverLog} onMouseLeave={handleMouseLeaveLog} icon={faPowerOff} /></i>
                            <Tooltip id='tt-deconnexion'/>
                      </div> 
                    </div>
                  :
                  <div className="navbar-nav mr-auto">
                    <NavLink to="/connexion" className="icon-user nav-link" data-tooltip-id="tt-connexion" data-tooltip-html="Connexion" data-tooltip-place="bottom" >
                          <i><FontAwesomeIcon  onMouseOver={handleMouseOverUser} onMouseLeave={handleMouseLeaveUser} icon={faPowerOff} /></i>
                          <i className="fa-regular fa-user"></i>
                          <Tooltip id='tt-connexion'/>
                    </NavLink>
                  </div>
                  }
                </div>
              </nav>    
            :
            <nav ref={stretchyNav} className={navVisible ? "cd-stretchy-nav nav-is-visible" : "cd-stretchy-nav"} id="scroll-nav">
                <div className="cd-nav-trigger" onClick={() => setNavVisible(!navVisible)}>
                    <span aria-hidden="true"></span>
                </div>
                <span aria-hidden="true" className="stretchy-nav-bg"></span>
                <ul>
                    <li><NavLink to="/"><span>Home</span></NavLink></li>
                    <li><NavLink to="/article"><span>Articles</span></NavLink></li>
                    <li><NavLink to="/ressource/exercice"><span>Exercices</span></NavLink></li>
                    <li><NavLink to="/ressource/programme"><span>Programmes</span></NavLink></li>
                    <li><NavLink to="/ressource/autre"><span>Autres Ressources</span></NavLink></li>
                    <li><NavLink to="/coaching"><span>Coaching</span></NavLink></li>
                    <li><NavLink to="/contact"><span>Contact</span></NavLink></li>
                    {currentUser ? 
                      <li className='compte'><NavLink to="/profil"><span>Compte</span></NavLink></li>
                      
                      :
                      <li className='connexion'><NavLink to="/connexion"><span>Connexion</span></NavLink></li>
                    }
                </ul>
                <span aria-hidden="true" className="stretchy-nav-bg"></span>
            </nav>
        }
    </header >
}

export default AppNavbar;